<template>
  <b-overlay :show="disabled" spinner-variant="light" spinner-type="grow" spinner-small>
    <b-row>
      <b-col>
        <b-form-select v-model="widget" :options="filteredWidgetList" :disabled="disabled" />
      </b-col>

      <b-col cols="auto">
        <b-button variant="primary" @click="addWidget" :disabled="disabled">
          {{ buttonText }}
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
export default {
  name: 'PagesWidgetPicker',
  props: {
    disabled: { type: Boolean },
    buttonText: { type: String, default: 'Add Widget' },
    omit: { type: Array, required: false, default: () => [] },
    allow: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      widget: 'text',
      widgetList: [
        { value: 'toplist', text: 'Toplist' },
        { value: 'tabs', text: 'Tabs' },
        { value: 'newsletter', text: 'Newsletter' },
        { value: 'related_products', text: 'Related Products' },
        { value: 'contact_form', text: 'Contact Form' },
        { value: 'image', text: 'Image' },
        { value: 'text', text: 'Text' },
        { value: 'category_toplist', text: 'Category Toplist' },
        { value: 'category_list', text: 'Category List' },
        { value: 'one_two_three', text: 'Static One Two Three Widget' },
        { value: 'featured_categories', text: 'Featured Categories' },
        { value: 'place_list', text: 'Place List' },
        { value: 'featured_places', text: 'Featured Places' },
        { value: 'featured_places_in_norway', text: 'Featured Places in Norway' },
        { value: 'static_two_col', text: 'Static 2 col (text and image)' },
        { value: 'static_giftcard', text: 'Static Open Giftcard Feature' },
        { value: 'dynamic_toplist', text: 'Dynamic Toplist' },
        { value: 'review_slideshow', text: 'A slideshow for reviews' },
        { value: 'dynamic_seo_block', text: 'Dynamic SEO block' },
      ],
    }
  },
  computed: {
    list() {
      return new Map(
        Object.entries({
          toplist: { type: 'toplist', content: { id: 0 } },
          text: { type: 'text', content: { content: '' } },
          newsletter: { type: 'newsletter', content: { title: '', full_width: true } },
          related_products: { type: 'related_products', content: { title: 'Related Products', count: 4 } },
          contact_form: { type: 'contact_form', content: { title: 'Say Hi!' } },
          tabs: { type: 'tabs', content: { tabs: [], title: '', link_text: '', link_url: '', type: 'tab_content' } },
          review_slideshow: {
            type: 'review_slideshow',
            content: { limit: 12, rating: 3, type: 'review_slideshow_items' },
          },
          image: { type: 'image', content: { src: '' } },
          category_toplist: {
            type: 'category_toplist',
            content: { id: 0, title: '', link_text: '', link_url: '', header: true, is_news: false },
          },
          one_two_three: { type: 'one_two_three', content: { title: '' } },
          static_giftcard: { type: 'static_giftcard', content: 'static' },
          dynamic_toplist: { type: 'dynamic_toplist', content: 'static' },
          static_two_col: {
            type: 'static_two_col',
            content: {
              text: '',
              btn_text: '',
              btn_link: '',
              image_position: 'left',
              image: { src: '', type: 'image' },
            },
          },
          featured_categories: {
            type: 'featured_categories',
            content: { items: [], title: '', link_text: '', link_url: '', type: 'featured_categories_content' },
          },
          category_list: {
            type: 'category_list',
            content: {
              items: [],
              title: '',
              link_text: '',
              link_url: '',
              type: 'category_list_content',
              full_width: true,
            },
          },
          featured_places: {
            type: 'featured_places',
            content: {
              items: [],
              title: '',
              link_text: '',
              link_url: '',
              widget_display: 'single',
              type: 'featured_places_content',
            },
          },
          featured_places_in_norway: {
            type: 'featured_places_in_norway',
            content: {
              items: [],
              title: '',
              link_text: '',
              link_url: '',
              widget_display: 'single',
              type: 'featured_places_in_norway_content',
            },
          },
          place_list: {
            type: 'place_list',
            content: {
              items: [],
              title: '',
              link_text: '',
              link_url: '',
              type: 'place_list_content',
              full_width: true,
            },
          },
          dynamic_seo_block: { type: 'dynamic_seo_block', content: 'static' },
        })
      )
    },
    filteredWidgetList() {
      return this.allow.length > 0
        ? this.widgetList.filter(widget => this.allow.includes(widget.value))
        : this.widgetList.filter(widget => !this.omit.includes(widget.value))
    },
  },
  methods: {
    addWidget() {
      this.$emit('widget', this.list.get(this.widget))
    },
  },
}
</script>
